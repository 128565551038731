import Checkbox from "../Checkbox";
import { IStatesSelectProps } from "./StatesSelect.structure";
import React from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import * as Styles from "./Styles";
import { BrazilianStatesArray } from "../../utils/BrazilianStates";

export default function StatesSelect({
  states,
  setStates,
  hasError,
}: IStatesSelectProps) {
  const [isOpened, setIsOpen] = React.useState<boolean>(false);
  const [statesArray, setStatesArray] =
    React.useState<string[]>(BrazilianStatesArray);

  const handleFilterState = (event: { target: EventTarget }) => {
    const { value } = event.target as HTMLInputElement;

    setStatesArray(
      BrazilianStatesArray.filter((state) =>
        state.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const handleInsertState = (state: string) => {
    if (!states.includes(state)) {
      setStates([...states, state]);
      return;
    }

    setStates(states.filter((item) => item !== state));
  };

  const handleSelectAll = () => {
    if (states.length === BrazilianStatesArray.length) {
      setStates([]);
    } else {
      setStates(BrazilianStatesArray);
    }
  };

  const MapStatesSelect = React.useMemo(
    () =>
      statesArray.map((state) => {
        return (
          <Styles.Item key={state}>
            <Checkbox
              isChecked={states?.includes(state)}
              onClick={() => handleInsertState(state)}
            />
            <p>{state}</p>
          </Styles.Item>
        );
      }),
    [statesArray, states]
  );

  const setPlaholder = () => {
    if (states?.length === 27) {
      return "Todos";
    }

    if (states?.length > 0) {
      return states?.join(", ");
    }

    return "Estado";
  };

  return (
    <Styles.Container hasError={hasError} data-testid="states-select">
      {hasError && <Styles.Error>Estado necessário</Styles.Error>}
      <Styles.Placeholder isOpened={isOpened} hasError={hasError}>
        <div className="boxInput">
          <input
            type="text"
            placeholder={setPlaholder()}
            onChange={handleFilterState}
            onFocus={() => {
              setIsOpen(true);
            }}
            max={new Date().getFullYear()}
          />
        </div>
        <MdOutlineKeyboardArrowDown
          size={30}
          color={`${hasError ? "var(--hasError)" : "var(--gray4)"}`}
          onClick={() => {
            setIsOpen(!isOpened);
          }}
        />
      </Styles.Placeholder>
      {isOpened && (
        <Styles.Options id="options">
          <Styles.Item key="all">
            <Checkbox
              isChecked={states.sort() === BrazilianStatesArray.sort()}
              onClick={handleSelectAll}
            />
            <p>Todos</p>
          </Styles.Item>
          {MapStatesSelect}
        </Styles.Options>
      )}
    </Styles.Container>
  );
}
