import { MenuContext } from "../../contexts/Menu.provider";
import React from "react";
import { FormatDate } from "@qeshdev/front-utils-lib";
import AccordionOpened from "../AccordionOpened";
import { TranslateStates } from "../../utils/BrazilianStates";
import { TranslateMonths } from "../../utils/Months";
import * as Styles from "./Styles";

export default function OpenedMenu() {
  const { filesInfos } = React.useContext(MenuContext);
  const [accordionActive, setAccordionActive] = React.useState<string>("");

  const Files = React.useMemo(
    () =>
      typeof filesInfos === "object" &&
      filesInfos.map(
        ({ id, status, state_acronym, month, year, justify, created_at }) => {
          if (status === "pending") {
            const formatedDate = FormatDate(created_at);

            return (
              <AccordionOpened
                accordionActive={accordionActive}
                setAccordionActive={setAccordionActive}
                key={id}
                id={id}
                state={TranslateStates[state_acronym]}
                status={status}
                date={`${TranslateMonths[month]} ${year}`}
                justify={justify}
                created_at={formatedDate}
              />
            );
          }
        }
      ),
    [filesInfos, accordionActive]
  );

  return (
    <Styles.ContainerFiles data-testid="menuOpened">
      {Files}
    </Styles.ContainerFiles>
  );
}
