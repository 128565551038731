import IconX from "../../assets/X.svg";
import { INotificationProps } from "./Notification.structure";
import * as Styles from "./styles";

const Notification: React.FC<INotificationProps> = ({
  setAlert,
  alertMessage,
}) => {
  return (
    <Styles.NotificationArea>
      <Styles.NotificationIcon onClick={() => setAlert(false)}>
        <img src={IconX} alt="Ícone X" />
      </Styles.NotificationIcon>
      <Styles.NotificationText>{alertMessage}</Styles.NotificationText>
    </Styles.NotificationArea>
  );
};

export default Notification;
