import { IFilesStructure } from "../interfaces";
import { TranslateStates } from "./BrazilianStates";
import { EnglishMonths } from "./Months";

export function sortItems(data: IFilesStructure[]) {
  return data.sort(
    (prevItem: IFilesStructure, nextItem: IFilesStructure) =>
      new Date(nextItem.created_at).getTime() -
      new Date(prevItem.created_at).getTime()
  );
}

export function filterItems(
  data: IFilesStructure[],
  states: string[],
  month: string,
  year: string
) {
  return data.filter(
    (item: IFilesStructure) =>
      states.includes(TranslateStates[item.state_acronym]) &&
      item.month === EnglishMonths[month] &&
      item.year === +year
  );
}
