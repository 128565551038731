import * as Styles from "./Styles";
import { CgSearch } from "react-icons/cg";
import { ISearchButtonProps } from "./SearchButton.structure";

export default function SearchButton({
  isDisabled,
  onClick,
}: ISearchButtonProps) {
  return (
    <Styles.Button
      data-testid="search-button"
      onClick={onClick}
      disabled={isDisabled}
      isDisabled={isDisabled}
    >
      <CgSearch
        data-testid="search-icon"
        size={20}
        color={isDisabled ? "var(--gray3)" : "var(--gray4)"}
      />
    </Styles.Button>
  );
}
