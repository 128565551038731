import { ICheckboxProps } from "./Checkbox.structure";
import * as Styles from "./Styles";

export default function Checkbox({ isChecked, onClick }: ICheckboxProps) {
  return (
    <Styles.Container onClick={onClick} data-testid="checkbox">
      <Styles.Input
        isChecked={isChecked}
        checked={isChecked}
        type="checkbox"
        data-testid="input"
      />
      <Styles.Span />
    </Styles.Container>
  );
}
