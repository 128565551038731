import { FormatDate } from "@qeshdev/front-utils-lib";
import * as Styles from "../Styles";
import { IPropsModalRecuseHistoric } from "./ModalRecuseHistoric.structure";
import CloseIcon from "../../CloseIcon";
import { EnglishMonths } from "../../../utils/Months";

const ModalRecuseHistoric = ({
  date,
  justify,
  author,
  setModalRecuseHistoric,
}: IPropsModalRecuseHistoric) => {
  const handleCloseModal = () => {
    setModalRecuseHistoric("");
  };

  const formatedDate = FormatDate(new Date(date));

  return (
    <Styles.Container>
      <Styles.ModalRecuse>
        <Styles.HeaderRecuse>
          <h2>Detalhamento do motivo</h2>
          <CloseIcon onClick={handleCloseModal} />
        </Styles.HeaderRecuse>
        <Styles.BoxInputsRecuse>
          <Styles.BoxText>
            <textarea
              cols={30}
              rows={11}
              disabled
              value={justify}
              data-testid="textarea-justify"
            />
            <Styles.UserAndButton>
              <p>{`${author} - ${formatedDate}`}</p>
              <Styles.ButtonRecuseHistoric onClick={handleCloseModal}>
                Entendido
              </Styles.ButtonRecuseHistoric>
            </Styles.UserAndButton>
          </Styles.BoxText>
        </Styles.BoxInputsRecuse>
      </Styles.ModalRecuse>
    </Styles.Container>
  );
};

export default ModalRecuseHistoric;
