import styled from "styled-components";

interface IMenuStylesProps {
  isSelected?: boolean;
}

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const StatusOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  gap: 2rem;
  margin-bottom: 10px;
`;

export const Option = styled.span<IMenuStylesProps>`
  font-size: 0.8rem;
  font-weight: 400;
  padding-bottom: 8px;
  color: ${({ isSelected }) =>
    isSelected ? "var(--primary)" : "var(--gray5)"};}};
  text-align: center;
  cursor: pointer;
  border-bottom: ${({ isSelected }) =>
    isSelected && "2px solid var(--primary)"};
  transition: 0.2s ease-in-out;
`;

export const BoxArchives = styled.div`
  width: 100%;
  height: 300px;
`;

export const ContainerFiles = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  gap: 15px;
`;
