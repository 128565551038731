import styled from "styled-components";

export const Icon = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const FirstLine = styled.div`
  width: 100%;
  height: 25%;
  border-radius: 5px;
  background-color: var(--error);
  transform: translateY(50%) rotate(-45deg);
`;

export const SecondLine = styled.div`
  width: 100%;
  height: 25%;
  border-radius: 5px;
  background-color: var(--error);
  transform: translateY(-50%) rotate(45deg);
`;
