import styled, { keyframes } from "styled-components";

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const NotificationIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  font-size: 30px;

  img {
    width: 20px;
    color: var(--white);
  }
`;

export const NotificationText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: 2%;
  width: 90%;
  font-size: 1rem;
  font-weight: 700;
  color: var(--white);
`;

export const NotificationArea = styled.div`
  position: absolute;
  top: 0;
  width: 30vw;
  min-width: 200px;
  height: 55px;
  background: var(--error);
  border: var(--error) solid 1px;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.5rem;
  animation: ${FadeIn} 0.4s;

  @media only screen and (max-width: 1000px) {
     {
      height: 55px;
    }

    ${NotificationIcon} {
      font-size: 20px;
    }

    ${NotificationText} {
      font-size: 13px;
    }
  }
`;
