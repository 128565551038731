import { MenuContext } from "../../contexts/Menu.provider";
import React from "react";
import { FormatDate } from "@qeshdev/front-utils-lib";
import AccordionHistoric from "../AccordionHistoric";
import { TranslateStates } from "../../utils/BrazilianStates";
import { TranslateMonths } from "../../utils/Months";
import * as Styles from "./Styles";

export default function HistoricMenu() {
  const { filesInfos } = React.useContext(MenuContext);
  const [accordionActive, setAccordionActive] = React.useState<string>("");

  const Files = React.useMemo(() => {
    return (
      typeof filesInfos === "object" &&
      filesInfos.map(
        ({
          id,
          status,
          state_acronym,
          month,
          year,
          author,
          justify,
          created_at,
          status_date,
        }) => {
          if (status === "accepted" || status === "recused") {
            const formatedDate = FormatDate(created_at);

            return (
              <AccordionHistoric
                key={id}
                accordionActive={accordionActive}
                setAccordionActive={setAccordionActive}
                id={id}
                state={TranslateStates[state_acronym]}
                status={status}
                date={`${TranslateMonths[month]} ${year}`}
                author={author}
                justify={justify}
                created_at={formatedDate}
                status_date={status_date}
              />
            );
          }
        }
      )
    );
  }, [filesInfos, accordionActive]);

  return (
    <Styles.ContainerFiles data-testid="menuHistoric">
      {Files}
    </Styles.ContainerFiles>
  );
}
