import CloseIcon from "../../CloseIcon";
import * as Styles from "../Styles";
import React from "react";
import { IPropsModalRecuseDate } from "./ModalRecuse.structure";
import { verifyHourError } from "../../../utils/VerifyHourError";
import FilesDimp from "../../../services/FilesDimp";
import { masks } from "../../../masks";
import { verifyDateError } from "../../../utils/VerifyDateError";

const ModalRecuseDate = ({
  isDisabled,
  dateDisabled,
  hourDisabled,
  justifyDisabled,
  modalRecuseInfo,
  setModalRecuseInfo,
}: IPropsModalRecuseDate) => {
  const [date, setDate] = React.useState<string>(dateDisabled || "");
  const [time, setTime] = React.useState<string>(hourDisabled || "");
  const [justify, setJustify] = React.useState<string>(justifyDisabled || "");
  const [timeError, setTimeError] = React.useState<boolean>(false);
  const [dateError, setDateError] = React.useState<boolean>(false);
  const [dateIsEmpty, setDateIsEmpty] = React.useState<boolean>(false);
  const [timeIsEmpty, setTimeIsEmpty] = React.useState<boolean>(false);
  const recusedStatus = "recused";

  const handleCloseModal = () => {
    setModalRecuseInfo({ isOpened: false, id: "" });
  };

  const showRequiredText = () => {
    if (!date) {
      setDateIsEmpty(true);
    }

    if (!time) {
      setTimeIsEmpty(true);
    }

    const isTimeWrong = verifyHourError(time);

    if (isTimeWrong) {
      setTimeError(true);
    }

    const isDateWrong = verifyDateError(date);

    if (isDateWrong) {
      setDateError(true);
    }
  };

  const handleClick = async () => {
    const isTimeWrong = verifyHourError(time);
    const isDateWrong = verifyDateError(date);

    if (!date || !time || isTimeWrong || isDateWrong) {
      showRequiredText();
      return;
    }

    const dateArray = date.split("/");
    const day = Number(dateArray[0]);
    const month = Number(dateArray[1]) - 1;
    const year = Number(dateArray[2]);
    const hour = Number(time.slice(0, 2));
    const minutes = Number(time.slice(-2));

    const dateToSave = new Date(year, month, day, hour, minutes);

    await FilesDimp.updateFile(
      modalRecuseInfo.id,
      recusedStatus,
      dateToSave,
      "Gabriel",
      justify
    );

    handleCloseModal();
  };

  return (
    <Styles.Container>
      <Styles.ModalRecuse>
        <Styles.HeaderRecuse>
          <h2>Data de recusa</h2>
          <CloseIcon onClick={handleCloseModal} />
        </Styles.HeaderRecuse>
        <Styles.BoxInputsRecuse>
          <Styles.BoxInlineInputs>
            <Styles.InputError>
              <label htmlFor="inputDate">
                Data de recusa
                {dateIsEmpty ? (
                  <small> - Campo obrigatório</small>
                ) : dateError ? (
                  <small> - Data inválida</small>
                ) : (
                  <></>
                )}
              </label>
              <input
                data-testid="inputDate"
                id="inputDate"
                maxLength={10}
                value={date}
                placeholder="dd/mm/aaaa"
                onChange={(event) => {
                  setDate(masks.date(event.target.value));
                  setDateError(false);
                  setDateIsEmpty(false);
                }}
              />
            </Styles.InputError>

            <Styles.InputError>
              <label htmlFor="inputTime">
                Hora de recusa
                {timeIsEmpty ? (
                  <small> - Campo obrigatório</small>
                ) : timeError ? (
                  <small> - Hora inválida</small>
                ) : (
                  <></>
                )}
              </label>
              <input
                data-testid="inputTime"
                id="inputTime"
                value={time}
                placeholder="00:00"
                autoComplete="false"
                maxLength={5}
                onChange={(event) => {
                  setTime(masks.hour(event.target.value));
                  setTimeError(false);
                  setTimeIsEmpty(false);
                }}
              />
            </Styles.InputError>
          </Styles.BoxInlineInputs>

          <Styles.Subtitle>
            <h2>Detalhamento do motivo</h2>
            <small>(não obrigatório)</small>
          </Styles.Subtitle>
          <Styles.BoxText>
            <textarea
              cols={30}
              rows={10}
              placeholder="Escreva aqui o motivo que justifique a recusa"
              disabled={isDisabled}
              value={justify}
              onChange={(event) => setJustify(event.target.value)}
              data-testid="textarea"
            />
            <Styles.ButtonRecuse onClick={handleClick}>
              Recusar
            </Styles.ButtonRecuse>
          </Styles.BoxText>
        </Styles.BoxInputsRecuse>
      </Styles.ModalRecuse>
    </Styles.Container>
  );
};

export default ModalRecuseDate;
