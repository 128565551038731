import api from ".";
import * as Interface from "../interfaces";

class LinkFile {
  /**
   * @description Get file link
   * @params id
   * @returns {Promise<Interface.ILinkRequisition  | false>} An object when requisition is true or false in error
   */
  async getLink(id: string): Promise<Interface.ILinkRequisition | false> {
    try {
      const { data } = await api.get("/files/download", {
        params: {
          id,
        },
      });

      return data;
    } catch (error: any) {
      return false;
    }
  }
}

export default new LinkFile();
