import styled, { keyframes } from "styled-components";

interface ISecondaryButtonStylesProps {
  isDisabled?: boolean;
  isLoading?: boolean;
}

const Smooth = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  } 
`;

const InfiniteRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
`;

export const Button = styled.button<ISecondaryButtonStylesProps>`
  width: 9.8rem;
  height: 2.81rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1.6rem;
  border-radius: 0.5rem;
  border: 1px solid
    ${({ isDisabled, isLoading }) =>
      isDisabled || isLoading ? "var(--gray3)" : "var(--primary)"};
  background-color: transparent;
  color: ${({ isDisabled, isLoading }) =>
    isDisabled || isLoading ? "var(--gray3)" : "var(--primary)"};
  font-size: 0.9rem;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  animation: ${Smooth} 0.5s ease-in-out;

  &:hover {
    color: ${({ isDisabled, isLoading }) =>
      isDisabled || isLoading ? "var(--gray3)" : "var(--primaryHover)"};
    border-color: ${({ isDisabled, isLoading }) =>
      isDisabled || isLoading ? "var(--gray3)" : "var(--primaryHover)"};
  }

  svg {
    cursor: ${({ isLoading }) => (isLoading ? "default" : "pointer")};
  }

  .loadingIcon {
    animation: ${InfiniteRotate} 2s infinite linear;
  }
`;
