import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const Modal = styled.div`
  width: 90%;
  max-width: 612px;
  height: 392px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  padding: 25px 0 39px 0;
  background-color: var(--white);
`;

export const ModalRecuse = styled.div`
  width: 90%;
  max-width: 612px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  border-radius: 24px;
  padding: 25px 2.5% 0px 2.5%;
  background-color: var(--white);
  gap: 30px;
`;

export const Header = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  border-bottom: 1px solid var(--primary);

  h2 {
    font-size: 1.3rem;
    color: var(--gray5);
    margin: 0;
  }
`;

export const HeaderRecuse = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  border-bottom: 1px solid var(--primary);

  h2 {
    font-size: 1.3rem;
    color: var(--gray5);
    margin: 0;
  }
`;

export const Form = styled.div`
  width: 90%;
  max-width: 400px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 23px;
`;

export const BoxInputsRecuse = styled.div`
  width: 100%;
  max-width: 559px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  gap: 23px;
`;

export const BoxInlineInputs = styled.div`
  width: 100%;
  max-width: 559px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 5%;
`;

export const Subtitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transform: translateY(80%);
  padding-bottom: 1px;
  border-bottom: 1px solid var(--primary);
  gap: 5px;

  h2 {
    font-size: 1.3rem;
    color: var(--gray5);
    margin: 0;
  }

  small {
    font-size: 0.9rem;
    color: var(--gray5);
    margin: 0;
  }
`;

export const BoxText = styled.div`
  width: 100%;
  max-width: 559px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-end;

  textarea {
    width: 100%;
    height: 300px;
    border: none;
    border-radius: 12px;
    padding: 1rem;
    font-size: 1rem;

    &::placeholder {
      color: var(--gray3);
    }

    &:focus {
      outline: none;
    }
  }
`;

export const InputError = styled.div`
  width: 100%;
  height: 4.69rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;

  label {
    font-size: 0.9rem;
    color: var(--gray5);
    margin: 0;
  }

  input {
    width: 100%;
    height: 3rem;
    border: 1px solid var(--gray2);
    border-radius: 0.5rem;
    padding: 0 1rem;
    font-size: 1rem;
    font-family: Roboto, sans-serif;
    -webkit-appearance: none;

    &::placeholder {
      color: var(--gray3);
    }
    
    &:focus {
      outline: 2px solid var(--gray5);
    }

    &::-webkit-inner-spin-button;
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  }

  small {
    font-size: 0.8rem;
    font-weight: bold;
    color: var(--error);
    width: 100%;
    text-align: left;
    transition: 0.2s ease-in-out;
  }
`;

export const UserAndButton = styled.div`
  width: 100%;
  height: 3.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 0.9rem;
    width: 100%;
  }
`;

export const Button = styled.button`
  width: 90%;
  max-width: 343px;
  height: 48px;
  font-size: 1rem;
  font-weight: bold;
  color: var(--white);
  background-color: var(--success);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: var(--successHover);
  }
`;

export const ButtonRecuse = styled.button`
  width: 90%;
  max-width: 167px;
  height: 48px;
  font-size: 1rem;
  font-weight: bold;
  color: var(--white);
  background-color: var(--error);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transform: translateY(-80%);
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: var(--errorHover);
  }
`;

export const ButtonRecuseHistoric = styled.button`
  width: 90%;
  max-width: 167px;
  height: 48px;
  font-size: 1rem;
  font-weight: bold;
  color: var(--white);
  background-color: var(--success);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transform: translateY(-60%);
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: var(--successHover);
  }
`;
