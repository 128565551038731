import React from "react";
import HistoricMenu from "./HistoricMenu";
import { ISelectedMenu } from "./Menu.structure";
import OpenedMenu from "./OpenedMenu";
import FilesDimp from "../../services/FilesDimp";
import * as Styles from "./Styles";
import { MenuContext } from "../../contexts/Menu.provider";
import ModalAcceptanceDate from "../Modals/ModalAcceptance";
import ModalRecuseDate from "../Modals/ModalRecuse";
import { sortItems } from "../../utils/ArrayManipulation";

export default function Menu() {
  const {
    alerts,
    setAlerts,
    setFilesInfos,
    modalAcceptanceInfo,
    setModalAcceptanceInfo,
    modalRecuseInfo,
    setModalRecuseInfo,
  } = React.useContext(MenuContext);
  const [menu, setMenu] = React.useState<string>("pending");

  const SelectedMenu: ISelectedMenu = {
    pending: <OpenedMenu />,
    historic: <HistoricMenu />,
  };

  const firstOption = "pending";
  const secondOption = "historic";

  const handleGetFiles = React.useCallback(async () => {
    const data = await FilesDimp.getFiles();

    if (!data) return;

    if (typeof data === "number") {
      setAlerts(data);
    } else {
      const files = sortItems(data);
      setFilesInfos(files);
    }
  }, [alerts, setFilesInfos]);

  React.useEffect(() => {
    handleGetFiles();
  }, [menu, modalAcceptanceInfo, modalRecuseInfo]);

  return (
    <>
      {modalAcceptanceInfo?.isOpened && (
        <ModalAcceptanceDate
          modalAcceptanceInfo={modalAcceptanceInfo}
          setModalAcceptanceInfo={setModalAcceptanceInfo}
        />
      )}
      {modalRecuseInfo?.isOpened && (
        <ModalRecuseDate
          modalRecuseInfo={modalRecuseInfo}
          setModalRecuseInfo={setModalRecuseInfo}
        />
      )}
      <Styles.Container>
        <Styles.StatusOptions>
          <Styles.Option
            isSelected={menu === firstOption}
            onClick={() => setMenu(firstOption)}
            data-testid="openedMenuButton"
          >
            Abertos
          </Styles.Option>
          <Styles.Option
            isSelected={menu === secondOption}
            onClick={() => setMenu(secondOption)}
            data-testid="historicMenuButton"
          >
            Histórico
          </Styles.Option>
        </Styles.StatusOptions>
        <Styles.BoxArchives>{SelectedMenu[menu]}</Styles.BoxArchives>
      </Styles.Container>
    </>
  );
}
