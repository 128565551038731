import Dimp from "./pages/Dimp";
import Globals from "./globals";
import api from "./services";
import React from "react";
import MenuProvider from "./contexts/Menu.provider";
import LoginService from "./services/Login";

interface IUserInfo {
  id: string;
  token: string;
  refresh_token: string;
}

export default function Root(props) {
  const env_type = process.env.ENV_TYPE;
  const [userInfo, setUserInfo] = React.useState(
    props?.customProps?.auth_token || ({} as IUserInfo)
  );

  const localLogin = React.useCallback(async () => {
    const email = process.env.LOCAL_ENV_USER;
    const password = process.env.LOCAL_ENV_PASSWORD;

    try {
      const localToken = await LoginService.login(email, password);
      const { token, refresh_token, id } = localToken;
      setUserInfo({ token, refresh_token, id });
    } catch (error) {
      return false;
    }
  }, []);

  React.useEffect(() => {
    document.title = "Admin | Fiscal - DIMP";
  }, []);

  React.useLayoutEffect(() => {
    if (env_type === "local") {
      localLogin();
    }

    if (env_type !== "local") {
      if (!userInfo.token) {
        window.location.href = "/";
        return;
      }
    }

    api.defaults.headers.common["Authorization"] = `bearer ${userInfo.token}`;
    api.defaults.headers.common["refresh_token"] = userInfo.refresh_token;
  }, [userInfo]);

  return (
    <>
      <MenuProvider>
        <Globals />
        <Dimp />
      </MenuProvider>
    </>
  );
}
