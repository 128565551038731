interface IMonths {
  [key: string]: string;
}

export const TranslateMonths: IMonths = {
  JANUARY: "Janeiro",
  FEBRUARY: "Fevereiro",
  MARCH: "Março",
  APRIL: "Abril",
  MAY: "Maio",
  JUNE: "Junho",
  JULY: "Julho",
  AUGUST: "Agosto",
  SEPTEMBER: "Setembro",
  OCTOBER: "Outubro",
  NOVEMBER: "Novembro",
  DECEMBER: "Dezembro",
};

export const EnglishMonths: IMonths = {
  Janeiro: "JANUARY",
  Fevereiro: "FEBRUARY",
  Março: "MARCH",
  Abril: "APRIL",
  Maio: "MAY",
  Junho: "JUNE",
  Julho: "JULY",
  Agosto: "AUGUST",
  Setembro: "SEPTEMBER",
  Outubro: "OCTOBER",
  Novembro: "NOVEMBER",
  Dezembro: "DECEMBER",
};

export const ArrayMonths = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
];
