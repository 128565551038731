import { createGlobalStyle } from "styled-components";

const Globals = createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  :root {
    --primary:#42C1C7;
    --secondary:#521AA3;
    --primaryHover: #15898C;

    --error:#DA1E28;
    --errorHover: #B20009;
    --alert:#FCBE07;
    --alertHover: #DEAC00;
    --success:#00C58A;
    --successHover:#089269;

    --white:#FFFFFF;
    --gray1:#F4F4F4;
    --gray2:#E0E0E0;
    --gray3:#A8A8A8;
    --gray4:#6F6F6F;
    --gray5:#393939;
    --gray6:#1A1A1A;
  }
`;

export default Globals;
