import MonthYearPicker from "../MonthYearPicker";
import SearchButton from "../SearchButton";
import React from "react";
import * as Styles from "./Styles";
import SecondaryButton from "../SecondaryButton";
import StatesSelect from "../StatesSelect";
import TerciaryButton from "../TerciaryButton";
import { MenuContext } from "../../contexts/Menu.provider";
import FilesDimp from "../../services/FilesDimp";
import { BrazilianStates, TranslateStates } from "../../utils/BrazilianStates";
import { EnglishMonths } from "../../utils/Months";
import { IVerifyAlert } from "../Menu/Menu.structure";
import Notification from "../Notification";
import { filterItems, sortItems } from "../../utils/ArrayManipulation";
import { IFilesStructure } from "../../interfaces";

const Header = () => {
  const {
    alerts,
    setAlerts,
    filesInfos,
    setFilesInfos,
    month,
    year,
    states,
    setMonth,
    setYear,
    setStates,
    isLoadingHeader,
    setIsLoadingHeader,
  } = React.useContext(MenuContext);
  const [monthError, setMonthError] = React.useState<boolean>(false);
  const [stateError, setStateError] = React.useState<boolean>(false);

  const VerifyAlert: IVerifyAlert = {
    400: (
      <Notification
        setAlert={setAlerts}
        alertMessage='Arquivo gerado anteriormente e está com status "Aberto" ou
  "Aceito"'
      />
    ),
    401: (
      <Notification
        setAlert={setAlerts}
        alertMessage='Arquivo gerado anteriormente e está com status "Aberto" ou
  "Aceito"'
      />
    ),
    404: (
      <Notification
        setAlert={setAlerts}
        alertMessage='Arquivo gerado anteriormente e está com status "Aberto" ou
  "Aceito"'
      />
    ),
    500: (
      <Notification
        setAlert={setAlerts}
        alertMessage="Sistema indisponível, tente novamente mais tarde"
      />
    ),
  };

  const formatData = React.useCallback(
    (data: IFilesStructure[]) => {
      const sort = sortItems(data);
      return filterItems(sort, states, month, year);
    },
    [states, month, year]
  );

  const someSelectIsEmpty = React.useCallback(() => {
    if ((!states?.length && !month) || (!states?.length && !year)) {
      setStateError(true);
      setMonthError(true);
      return true;
    }

    if (!month || !year) {
      setMonthError(true);
      return true;
    }

    if (!states.length) {
      setStateError(true);
      return true;
    }

    setStateError(false);
    setMonthError(false);
    return false;
  }, [month, states, year]);

  const getFiles = async () => {
    const data = await FilesDimp.getFiles();

    const isEmpty = someSelectIsEmpty();

    if (isEmpty) return;

    if (data && typeof data !== "number" && typeof filesInfos !== "number") {
      const files = formatData(data);
      setFilesInfos(files);
    }
  };

  const generateFiles = async () => {
    const isEmpty = someSelectIsEmpty();

    if (isEmpty) return;

    setIsLoadingHeader(true);

    for (const state of states) {
      const data = await FilesDimp.createFile(
        BrazilianStates[state],
        EnglishMonths[month],
        +year
      );

      if (data && typeof data === "number") {
        setAlerts(data);
      }
    }

    setIsLoadingHeader(false);

    getFiles();
  };

  const reRenderFiles = async () => {
    const data = await FilesDimp.getFiles();

    if (data && typeof data !== "number") {
      const files = sortItems(data);
      setFilesInfos(files);
    }
  };

  const handleCleanFilter = () => {
    setMonth("");
    setYear("");
    setStates([]);
  };

  React.useEffect(() => {
    setMonthError(false);
  }, [month]);

  React.useEffect(() => {
    setStateError(false);
  }, [states]);

  return (
    <>
      {alerts && VerifyAlert[alerts]}
      <Styles.BoxHeader data-testid="header">
        <Styles.Title>Gerar arquivos fiscais</Styles.Title>
        <Styles.Subtitle>Filtre por estado e data</Styles.Subtitle>
        <Styles.BoxFilter>
          <div className="boxes">
            <StatesSelect
              states={states}
              setStates={setStates}
              hasError={stateError}
            />
            <MonthYearPicker
              month={month}
              year={year}
              setMonth={setMonth}
              setYear={setYear}
              hasError={monthError}
            />
            <SearchButton onClick={getFiles} />
            <SecondaryButton
              text="Gerar arquivos"
              onClick={generateFiles}
              isLoading={isLoadingHeader}
            />
          </div>
          <TerciaryButton
            onClick={() => {
              handleCleanFilter();
              reRenderFiles();
            }}
            text="Limpar filtros"
          />
        </Styles.BoxFilter>
      </Styles.BoxHeader>
    </>
  );
};

export default Header;
