import styled, { keyframes } from "styled-components";

interface MonthYearPickerProps {
  isOpened?: boolean;
  hasError?: boolean;
}

const OpeningAnimation = keyframes`
  0% {
    height: 0;
  }

  70% {
    height: 11.875rem;
  }

  100% {
    height: 12.5rem;
  }
`;

const RotateOpen = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    trasnform: rotate(180deg);
  }
`;

const RotateClose = keyframes`
  0% {
    trasnform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
`;

export const Container = styled.div<MonthYearPickerProps>`
  position: ${({ isOpened }) => (isOpened ? "absolute" : "relative")};
  width: 90%;
  max-width: 12.25rem;
  height: 2.81rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--white);
  transition: 0.2s ease-in-out;
  border: ${({ hasError }) =>
    hasError ? "2px solid var(--error)" : "1px solid var(--gray2)"};
  border-bottom: none;
`;

export const Placeholder = styled.div<MonthYearPickerProps>`
  width: 12.25rem;
  height: 2.81rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 13px;
  font-size: 14px;
  color: var(--gray4);
  text-transform: capitalize;
  background-color: var(--white);
  border-right: 1px solid var(--gray2);
  border-left: 1px solid var(--gray2);
  border-bottom: 1px solid var(--gray2);

  div {
    height: 2.81rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .boxInput {
    width: 38px;
    height: 20px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${({ isOpened }) =>
      isOpened ? "1px solid var(--gray4)" : "none"};
  }

  input {
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: ${({ hasError }) => (hasError ? "var(--error)" : "var(--gray4)")};
    text-transform: capitalize;
    border: none;
    outline: none;
  }

  input::placeholder {
    color: ${({ hasError }) => (hasError ? "var(--error)" : "var(--gray4)")};
  }

  p {
    font-size: 14px;
    color: ${({ hasError }) => (hasError ? "var(--error)" : "var(--gray4)")};
    text-transform: capitalize;
  }

  svg {
    transform: ${({ isOpened }) =>
      isOpened ? "rotate(180deg)" : "rotate(0deg)"};
    animation: ${({ isOpened }) => (isOpened ? RotateOpen : RotateClose)} 0.2s
      ease-in-out;
    cursor: pointer;
    transition-fill-mode: forwards;
  }
`;

export const SmallError = styled.small`
  position: absolute;
  width: 12.25rem;
  font-size: 0.65rem;
  font-weight: bold;
  color: var(--error);
  text-align: left;
  transition: 0.2s ease-in-out;
  transform: translateY(-130%);
`;

export const BoxYear = styled.div<MonthYearPickerProps>`
  width: 12.25rem;
  min-height: 2.81rem;
  display: ${({ isOpened }) => (isOpened ? "flex" : "none")};
  justify-content: flex-end;
  align-items: center;
  padding: 0 0.5rem;
  border-right: 1px solid var(--gray2);
  border-left: 1px solid var(--gray2);

  p {
    display: ${({ isOpened }) => !isOpened && "none"};
    font-size: 14px;
    color: var(--gray2);
    font-weight: 700;
    margin: 0;
  }
`;

export const Options = styled.div`
  position: absolute;
  transform: translateY(90px);
  width: 12.25rem;
  max-height: 12.5rem;
  background: var(--white);
  overflow: auto;
  border-left: 1px solid var(--gray2);
  border-right: 1px solid var(--gray2);
  border-bottom: 1px solid var(--gray2);
  display: flex
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  animation: ${OpeningAnimation} 0.2s
    ease-in-out;

  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--gray2);
    border-radius: 0.375rem;
    border: none;
  }
`;

export const Item = styled.p`
  color: var(--gray4);
  font-size: 14px;
  text-transform: capitalize;
  width: 100%;
  height: 2.5rem;
  padding: 0 0.813rem;
  line-height: 2.5rem;
  cursor: pointer;
  border-bottom: 1px solid var(--gray2);
  margin: 0;
`;

export const Error = styled.small`
  position: absolute;
  width: 12.25rem;
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--error);
  text-align: left;
  transition: 0.2s ease-in-out;
  transform: translateY(-130%);
`;
