import api from ".";
import * as Interface from "../interfaces";

class FilesDimp {
  /**
   * @description Create file with the state, month, year and author provided
   * @params {state_acronym, month, year, author?} or nothing
   * @returns {Promise<Interface.IFilesStructure | number | false>} An object when requisition is true or false / number in error
   */
  async createFile(
    state_acronym: string,
    month: string,
    year: number,
    author?: string
  ): Promise<Interface.IFilesStructure[] | number | false> {
    const body = {
      state_acronym,
      month,
      year,
      author,
    };

    try {
      const { data } = await api.post("/files", body);

      return data;
    } catch (error: any) {
      const errorRes = error.response?.status;

      return errorRes;
    }
  }

  /**
   * @description Get all files infos
   * @params {status, state_acronym?, month?, year?} or nothing
   * @returns {Promise<Interface.IFilesStructure[] | number | false>} An object when requisition is true or false / number in error
   */
  async getFiles(): Promise<Interface.IFilesStructure[] | number | false> {
    try {
      const { data } = await api.get("/files");

      return data;
    } catch (error: any) {
      const errorRes = error.response?.status;

      return errorRes;
    }
  }

  /**
   * @description Update file status, status_date, author and justify infos
   * @params {id, status, status_date, author?, justify?} or nothing
   * @returns {Promise<Interface.IFilesStructure | false>} An object when requisition is true or false / number in error
   */
  async updateFile(
    id: string,
    status: string,
    status_date: Date,
    author?: string,
    justify?: string
  ): Promise<Interface.IFilesStructure[] | number | false> {
    try {
      return await api.patch(
        "/files",
        {},
        {
          params: {
            id,
            status,
            status_date,
            author,
            justify,
          },
        }
      );
    } catch (error: any) {
      const errorRes = error.response?.status;

      return errorRes;
    }
  }
}

export default new FilesDimp();
