import * as Styles from "./Styles";
import { ITerciaryButtonProps } from "./TerciaryButton.structure";

export default function TerciaryButton({
  text,
  isDisabled,
  onClick,
}: ITerciaryButtonProps) {
  return (
    <Styles.Button
      onClick={onClick}
      disabled={isDisabled}
      isDisabled={isDisabled}
      data-testid="terciary-button"
    >
      {text}
    </Styles.Button>
  );
}
