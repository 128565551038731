import styled, { keyframes } from "styled-components";

interface IAccordionStylesProps {
  isLoading?: boolean;
  isActive?: boolean;
  color?: string;
  status?: string;
}

const rotateUp = keyframes`
  0% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(0deg);
  }
`;

const rotateDown = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(90deg);
  }
`;

const InfiniteRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
`;

const Opening = keyframes`
  from {
    opacity: 0;
    height: 0;
  }

  to {
    opacity: 1;
    height: 7rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: var(--white);
  border-radius: 0.5rem;
`;

export const Summary = styled.div<IAccordionStylesProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  height: 4.2rem
  cursor: pointer;
  padding: 0 0.5rem;
  border-bottom: ${({ isActive }) =>
    isActive ? "1px solid var(--gray2)" : "none"};
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 4.2rem;
`;

export const IconContent = styled.div<IAccordionStylesProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 10%;

  svg {
    transform: ${({ isActive }) =>
      isActive ? "rotate(90deg)" : "rotate(0deg)"};
    animation: ${({ isActive }) => (isActive ? rotateDown : rotateUp)} 0.3s
      linear;
    width: 1.55rem;
    height: auto;
    color: var(--gray4);
    cursor: pointer;
  }
`;

export const Details = styled.div<IAccordionStylesProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 7rem;
  transition: all 0.3s ease-in-out;
  animation: ${Opening} 0.3s ease-in-out;
  animation-direction: ${({ isActive }) => (isActive ? "normal" : "reverse")};
`;

export const State = styled.p`
  width: 23%;
  font-size: 1rem;
  font-weight: bold;
  color: var(--gray4);
`;

export const Date = styled.p`
  width: 23%;
  text-align: center;
  font-size: 1rem;
  color: var(--gray4);
`;

export const Status = styled.p`
  width: 23%;
  text-align: center;
  font-size: 1rem;
  color: ${({ children }) =>
    children === "Recusado" ? "var(--error)" : "var(--success)"};
`;

export const Download = styled.a<IAccordionStylesProps>`
  fontsize: 1rem;
  color: ${({ isLoading }) => (isLoading ? "var(--gray4)" : "var(--primary)")};
  display: flex;
  justify-content: center;
  align-itens: center;
  line-height: 1.5rem;
  text-align: center;
  width: 23%;
  gap: 4px;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  cursor: ${({ isLoading }) => (isLoading ? "default" : "pointer")};

  svg {
    width: 1.5rem;
    height: auto;
    cursor: ${({ isLoading }) => (isLoading ? "default" : "pointer")};
    color: ${({ isLoading }) =>
      isLoading ? "var(--gray4)" : "var(--primary)"};
  }

  .loadingIcon {
    animation: ${InfiniteRotate} 2s infinite linear;
  }
`;

export const BoxDetails = styled.div<IAccordionStylesProps>`
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem 0.7rem 0.5rem;
  gap: 20px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  button {
    font-size: 0.9rem;
    color: var(--primary);
    cursor: ${({ status }) => (status === "recused" ? "pointer" : "default")};
    background-color: transparent;
    border: none;
    padding: 0;
  }
`;
