export function verifyDateError(date: string): boolean | null {
  const day = Number(date.split("/")[0]);
  const month = Number(date.split("/")[1]);
  const year = Number(date.split("/")[2]);
  const currentYear = new Date().getFullYear();

  const currentDate = new Date();
  const userDate = new Date(year, month - 1, day);

  if (date.length === 10) {
    if (currentDate < userDate) {
      return true;
    }

    if (year > currentYear || year < 2020) {
      return true;
    }

    if (month > 12 || month < 1) {
      return true;
    }

    if (day > 31 || day < 1) {
      return true;
    }

    if (month === 2) {
      if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
        if (day > 29) {
          return true;
        }
      } else if (day > 28) {
        return true;
      }
    }

    if (
      (month === 1 ||
        month === 3 ||
        month === 5 ||
        month === 7 ||
        month === 8 ||
        month === 10 ||
        month === 12) &&
      day > 31
    ) {
      return true;
    }

    if (
      (month === 4 || month === 6 || month === 9 || month === 11) &&
      day > 30
    ) {
      return true;
    }

    return false;
  }

  return true;
}
