import React from "react";
import { IAccordionProps } from "./AccordionOpened.structure";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { BiLoaderCircle } from "react-icons/bi";
import * as Styles from "./Styles";
import { FaFileDownload } from "react-icons/fa";
import { MenuContext } from "../../contexts/Menu.provider";
import { DownloadFile } from "../../utils/DownloadFiles";

export default function AccordionOpened({
  accordionActive,
  setAccordionActive,
  id,
  state,
  date,
  created_at,
}: IAccordionProps) {
  const { setModalAcceptanceInfo, setModalRecuseInfo } =
    React.useContext(MenuContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleOpenModalAcceptance = () => {
    setModalAcceptanceInfo({ isOpened: true, id });
  };

  const handleOpenModalRecuse = () => {
    setModalRecuseInfo({ isOpened: true, id });
  };

  const toggleOpenAccordion = () => {
    accordionActive === id ? setAccordionActive("") : setAccordionActive(id);
  };

  return (
    <Styles.Content>
      <Styles.Summary isActive={accordionActive === id}>
        <Styles.Title>
          <Styles.State>{state}</Styles.State>
          <Styles.Date>{date}</Styles.Date>
          <Styles.Download
            data-testid="downloadButton"
            isLoading={isLoading}
            {...(!isLoading && {
              onClick: () => DownloadFile(id, setIsLoading),
            })}
          >
            {isLoading ? (
              <BiLoaderCircle className="loadingIcon" />
            ) : (
              <>
                <>Download</>
                <FaFileDownload />
              </>
            )}
          </Styles.Download>
        </Styles.Title>
        <Styles.IconContent isActive={accordionActive === id}>
          <IoIosArrowDroprightCircle
            data-testid="openIcon"
            onClick={toggleOpenAccordion}
          />
        </Styles.IconContent>
      </Styles.Summary>

      {accordionActive === id && (
        <Styles.Details isActive={accordionActive === id}>
          <Styles.BoxDetails>
            <p>{`Data de criação do arquivo - ${created_at}`}</p>
            <div>
              <Styles.AcceptButton onClick={handleOpenModalAcceptance}>
                Aceito
              </Styles.AcceptButton>
              <Styles.RecuseButton onClick={handleOpenModalRecuse}>
                Recusado
              </Styles.RecuseButton>
            </div>
          </Styles.BoxDetails>
        </Styles.Details>
      )}
    </Styles.Content>
  );
}
