export const verifyHourError = (time: string) => {
  const arrayHour = time.split(":");

  const hour = Number(arrayHour[0]);
  const minutes = Number(arrayHour[1]);

  if (hour === 0 && minutes === 0) {
    return true;
  }

  if (hour < 0 || hour > 23 || minutes < 0 || minutes > 59) {
    return true;
  }

  return false;
};
