import styled, { keyframes } from "styled-components";

interface ITerciarButtonProps {
  isDisabled?: boolean;
}

const Smooth = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  } 
`;

export const Button = styled.button<ITerciarButtonProps>`
  height: 45px;
  padding: 0.8rem;
  border-radius: 0.5rem;
  border: 1px dashed
    ${({ isDisabled }) => (isDisabled ? "var(--gray1)" : "var(--gray2)")};
  background-color: var(--white);
  color: ${({ isDisabled }) => (isDisabled ? "var(--gray2)" : "var(--gray4)")};
  font-size: 0.9rem;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  animation: ${Smooth} 0.5s ease-in-out;

  &:hover {
    color: ${({ isDisabled }) =>
      isDisabled ? "var(--gray1)" : "var(--gray4)"};
    border-color: ${({ isDisabled }) =>
      isDisabled ? "var(--gray1)" : "var(--gray4)"};
  }
`;
