import styled from "styled-components";

export const BoxHeader = styled.header`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
`;

export const Title = styled.h1`
  font-size: 1.2rem;
  width: 100%;
  margin: 0;
`;

export const Subtitle = styled.h2`
  font-size: 1.5rem;
  width: 100%;
  margin: 0 0 12px 0;
`;

export const BoxFilter = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .boxes {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }
`;
