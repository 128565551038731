import { IMenuContext, IProps } from "./MenuProvider.structure";
import React from "react";
import { IFilesStructure } from "../interfaces";
import { IModalAcceptanceInfo } from "../components/Modals/ModalAcceptance/ModalAcceptance.structure";
import { IModalRecuseInfo } from "../components/Modals/ModalRecuse/ModalRecuse.structure";

export const MenuContext = React.createContext({} as IMenuContext);

const MenuProvider: React.FC<IProps> = ({ children }) => {
  const [filesInfos, setFilesInfos] = React.useState<
    IFilesStructure[] | number
  >([]);
  const [month, setMonth] = React.useState<string>("");
  const [year, setYear] = React.useState<string>("");
  const [states, setStates] = React.useState<string[]>([]);
  const [alerts, setAlerts] = React.useState<number | false>(false);
  const [isLoadingHeader, setIsLoadingHeader] = React.useState<boolean>(false);
  const [modalAcceptanceInfo, setModalAcceptanceInfo] =
    React.useState<IModalAcceptanceInfo>({
      isOpened: false,
      id: "",
    });
  const [modalRecuseInfo, setModalRecuseInfo] =
    React.useState<IModalRecuseInfo>({
      isOpened: false,
      id: "",
    });

  const value = React.useMemo(
    () => ({
      filesInfos,
      setFilesInfos,
      month,
      setMonth,
      year,
      setYear,
      states,
      setStates,
      alerts,
      setAlerts,
      modalAcceptanceInfo,
      setModalAcceptanceInfo,
      modalRecuseInfo,
      setModalRecuseInfo,
      isLoadingHeader,
      setIsLoadingHeader,
    }),
    [
      filesInfos,
      setFilesInfos,
      month,
      setMonth,
      year,
      setYear,
      states,
      setStates,
      alerts,
      setAlerts,
      modalAcceptanceInfo,
      setModalAcceptanceInfo,
      modalRecuseInfo,
      setModalRecuseInfo,
      isLoadingHeader,
      setIsLoadingHeader,
    ]
  );
  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

export default MenuProvider;
