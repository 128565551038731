import React from "react";
import {
  IAccordionProps,
  IStatesTranslate,
} from "./AccordionHistoric.structure";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { BiLoaderCircle } from "react-icons/bi";
import { FaFileDownload } from "react-icons/fa";
import * as Styles from "./Styles";
import LinkFile from "../../services/LinkFile";
import ModalRecuseHistoric from "../Modals/ModalRecuseHistoric";
import { DownloadFile } from "../../utils/DownloadFiles";

export default function AccordionHistoric({
  accordionActive,
  setAccordionActive,
  id,
  state,
  status,
  date,
  created_at,
  author,
  justify,
  status_date,
}: IAccordionProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [modalRecuseHistoric, setModalRecuseHistoric] =
    React.useState<string>("");

  const StatesTranslate: IStatesTranslate = {
    recused: "Recusado",
    accepted: "Aceito",
  };

  const handleOpenModal = () => {
    setModalRecuseHistoric(id);
  };

  const toggleOpenAccordion = () => {
    accordionActive === id ? setAccordionActive("") : setAccordionActive(id);
  };

  return (
    <>
      {modalRecuseHistoric === id && (
        <ModalRecuseHistoric
          date={status_date}
          justify={justify}
          author={author}
          setModalRecuseHistoric={setModalRecuseHistoric}
        />
      )}
      <Styles.Content>
        <Styles.Summary isActive={accordionActive === id}>
          <Styles.Title>
            <Styles.State>{state}</Styles.State>
            <Styles.Date>{date}</Styles.Date>
            <Styles.Status>{StatesTranslate[status]}</Styles.Status>
            <Styles.Download
              data-testid="downloadButton"
              isLoading={isLoading}
              {...(!isLoading && {
                onClick: () => DownloadFile(id, setIsLoading),
              })}
            >
              {isLoading ? (
                <BiLoaderCircle className="loadingIcon" />
              ) : (
                <>
                  <>Download</>
                  <FaFileDownload />
                </>
              )}
            </Styles.Download>
          </Styles.Title>
          <Styles.IconContent isActive={accordionActive === id}>
            <IoIosArrowDroprightCircle
              data-testid="openIcon"
              onClick={toggleOpenAccordion}
            />
          </Styles.IconContent>
        </Styles.Summary>

        {accordionActive === id && (
          <Styles.Details isActive={accordionActive === id}>
            <Styles.BoxDetails status={status}>
              <button
                {...(status === "recused" && { onClick: handleOpenModal })}
              >{`Data de criação do arquivo - ${created_at}`}</button>
            </Styles.BoxDetails>
          </Styles.Details>
        )}
      </Styles.Content>
    </>
  );
}
