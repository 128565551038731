import { IMonthYearPickerProps } from "./MonthYearPicker.structure";
import React from "react";
import * as Styles from "./Styles";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { TranslateMonths } from "../../utils/Months";

export default function MonthYearPicker({
  month,
  year,
  setMonth,
  setYear,
  hasError,
}: IMonthYearPickerProps) {
  const maxYear = new Date().getFullYear();

  const [isOpened, setIsOpened] = React.useState<boolean>(false);
  const [isYearError, setIsYearError] = React.useState<boolean>(false);

  const handleSelectMonth = (event: { target: EventTarget }) => {
    const { innerText } = event.target as HTMLInputElement;
    setMonth(innerText);
    setIsOpened(false);
  };

  const yearMask = (event: { target: EventTarget }) => {
    const { value } = event.target as HTMLInputElement;
    const data = value.replace(/\D/g, "");

    setIsYearError(Number(data) < 2020 || Number(data) > maxYear);
    setYear(data);
  };

  const verifyYear = () => {
    if (year === "" && !isOpened) {
      setYear("2022");
    }

    if (isOpened && month === "") {
      setYear("");
    }
  };

  const Months = React.useMemo(
    () =>
      Object.values(TranslateMonths).map((month, index) => {
        return (
          <Styles.Item
            data-testid={`p${index}-month`}
            key={month}
            onClick={handleSelectMonth}
          >
            {month}
          </Styles.Item>
        );
      }),
    [TranslateMonths]
  );

  return (
    <Styles.Container
      hasError={hasError || isYearError}
      data-testid="month-year-picker"
    >
      <Styles.Placeholder
        isOpened={isOpened}
        hasError={hasError || isYearError}
      >
        <div>
          <div className="boxInput">
            <input
              data-testid="input-year"
              type="text"
              placeholder="Data"
              onChange={(event) => {
                yearMask(event);
              }}
              onFocus={() => {
                verifyYear();
                setIsOpened(true);
              }}
              value={year}
              maxLength={4}
            />
          </div>

          <p>{month && `| ${month}`}</p>
        </div>
        <MdOutlineKeyboardArrowDown
          size={30}
          color={`${
            hasError || isYearError ? "var(--hasError)" : "var(--gray4)"
          }`}
          onClick={() => {
            verifyYear();
            setIsOpened(!isOpened);
          }}
        />
      </Styles.Placeholder>
      <Styles.BoxYear isOpened={isOpened}>
        <p>{year}</p>
      </Styles.BoxYear>
      {isYearError ? (
        <Styles.SmallError>
          O período deve estar entre 2020 e {maxYear}
        </Styles.SmallError>
      ) : (
        hasError && <Styles.Error>Data inválida</Styles.Error>
      )}
      {isOpened && <Styles.Options id="options">{Months}</Styles.Options>}
    </Styles.Container>
  );
}
