import { BiLoaderCircle } from "react-icons/bi";
import { ISecondaryButtonProps } from "./SecondaryButton.structure";
import * as Styles from "./Styles";

export default function SecondaryButton({
  text,
  isDisabled,
  isLoading,
  onClick,
}: ISecondaryButtonProps) {
  return (
    <Styles.Button
      data-testid="secondary-button"
      onClick={onClick}
      disabled={isDisabled}
      isDisabled={isDisabled}
      isLoading={isLoading}
    >
      {isLoading ? (
        <BiLoaderCircle
          size={28}
          color={isLoading ? "var(--gray3)" : "var(--primary)"}
          className="loadingIcon"
        />
      ) : (
        text
      )}
    </Styles.Button>
  );
}
