import styled, { keyframes } from "styled-components";

interface ISearchButtonProps {
  isDisabled?: boolean;
}

const Smooth = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  } 
`;

export const Button = styled.button<ISearchButtonProps>`
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px dashed var(--gray3);
  background-color: var(--white);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  animation: ${Smooth} 0.5s ease-in-out;

  &:hover {
    color: ${({ isDisabled }) =>
      isDisabled ? "var(--gray3)" : "var(--gray4)"};
    border-color: ${({ isDisabled }) =>
      isDisabled ? "var(--gray3)" : "var(--gray4)"};
  }

  &:disabled {
    cursor: default;
  }

  svg {
    margin-left: -2px;
  }
`;
