import CloseIcon from "../../CloseIcon";
import * as Styles from "../Styles";
import { masks } from "../../../masks";
import React from "react";
import { verifyHourError } from "../../../utils/VerifyHourError";
import { IPropsModalAcceptanceDate } from "./ModalAcceptance.structure";
import FilesDimp from "../../../services/FilesDimp";
import { verifyDateError } from "../../../utils/VerifyDateError";

const ModalAcceptanceDate = ({
  modalAcceptanceInfo,
  setModalAcceptanceInfo,
}: IPropsModalAcceptanceDate) => {
  const [date, setDate] = React.useState<string>("");
  const [time, setTime] = React.useState<string>("");
  const [timeError, setTimeError] = React.useState<boolean>(false);
  const [dateError, setDateError] = React.useState<boolean>(false);
  const [dateIsEmpty, setDateIsEmpty] = React.useState<boolean>(false);
  const [timeIsEmpty, setTimeIsEmpty] = React.useState<boolean>(false);
  const acceptedStatus = "accepted";

  const handleCloseModal = () => {
    setModalAcceptanceInfo({ isOpened: false, id: "" });
  };

  const showRequiredText = () => {
    if (!date) {
      setDateIsEmpty(true);
    }

    if (!time) {
      setTimeIsEmpty(true);
    }

    const isTimeWrong = verifyHourError(time);

    if (isTimeWrong) {
      setTimeError(true);
    }

    const isDateWrong = verifyDateError(date);

    if (isDateWrong) {
      setDateError(true);
    }
  };

  const handleClick = async () => {
    const isTimeWrong = verifyHourError(time);
    const isDateWrong = verifyDateError(date);

    if (!date.length || !time.length || isTimeWrong || isDateWrong) {
      showRequiredText();
      return;
    }

    const dateArray = date.split("/");
    const timeArray = time.split(":");
    const day = Number(dateArray[0]);
    const month = Number(dateArray[1]) - 1;
    const year = Number(dateArray[2]);
    const hour = Number(timeArray[0]);
    const minutes = Number(timeArray[1]);

    const dateToSave = new Date(year, month, day, hour, minutes);

    await FilesDimp.updateFile(
      modalAcceptanceInfo.id,
      acceptedStatus,
      dateToSave,
      "Gabriel"
    );

    handleCloseModal();
  };

  return (
    <Styles.Container>
      <Styles.Modal>
        <Styles.Header>
          <h2>Data de aceitação</h2>
          <CloseIcon onClick={handleCloseModal} />
        </Styles.Header>
        <Styles.Form>
          <Styles.InputError>
            <label htmlFor="inputDate">
              Data de aceitação
              {dateIsEmpty ? (
                <small> - Campo obrigatório</small>
              ) : dateError ? (
                <small> - Data inválida</small>
              ) : (
                <></>
              )}
            </label>
            <input
              data-testid="inputDate"
              id="inputDate"
              maxLength={10}
              value={date}
              placeholder="dd/mm/aaaa"
              onChange={(event) => {
                setDate(masks.date(event.target.value));
                setDateError(false);
                setDateIsEmpty(false);
              }}
            />
          </Styles.InputError>
          <Styles.InputError>
            <label htmlFor="inputTime">
              Hora de aceitação
              {timeIsEmpty ? (
                <small> - Campo obrigatório</small>
              ) : timeError ? (
                <small> - Hora inválida</small>
              ) : (
                <></>
              )}
            </label>
            <input
              data-testid="inputTime"
              id="inputTime"
              value={time}
              placeholder="00:00"
              autoComplete="false"
              maxLength={5}
              onChange={(event) => {
                setTime(masks.hour(event.target.value));
                setTimeError(false);
                setTimeIsEmpty(false);
              }}
            />
          </Styles.InputError>
        </Styles.Form>
        <Styles.Button data-testid="acceptButton" onClick={handleClick}>
          Aceitar
        </Styles.Button>
      </Styles.Modal>
    </Styles.Container>
  );
};

export default ModalAcceptanceDate;
