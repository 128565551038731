import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  padding: 40px 3%;
  background-color: var(--gray1);
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 60px;
`;

export const BoxStatusOptions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2.5rem;

  span {
    font-size: 0.8rem;
    font-weight: 400;
    padding-bottom: 8px;
    color: var(--gray5);
    text-align: center;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }
`;

export const TitleSelected = styled.span`
  color: var(--primary);
  border-bottom: 2px solid var(--primary);
`;

export const BoxArchives = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  gap: 15px;
`;

export const NotificationArea = styled.div`
  position: absolute;
  top: 5%;
  right: 1%;
  width: 22vw;
  min-width: 200px;
  height: 70px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  animation: fadeIn 0.4s;
`;
