import React from "react";
import LinkFile from "../services/LinkFile";

export const DownloadFile = async (
  id: string,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true);

  const data = await LinkFile.getLink(id);
  if (!data) return;

  const response = await fetch(data.locationSigned);
  const blob = await response.blob();
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", data.fileName.slice(5));
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setIsLoading(false);
};
