import * as Styles from "./Styles";
import Header from "../../components/Header";
import Menu from "../../components/Menu";

export default function Dimp() {
  return (
    <Styles.Container>
      <Header />
      <Menu />
    </Styles.Container>
  );
}
