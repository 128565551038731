import { IPropsCloseIcon } from "./CloseIcon.structure";
import * as Styles from "./Styles";

const CloseIcon = ({ onClick }: IPropsCloseIcon) => {
  return (
    <Styles.Icon onClick={onClick} data-testid="icon-close">
      <Styles.FirstLine />
      <Styles.SecondLine />
    </Styles.Icon>
  );
};

export default CloseIcon;
