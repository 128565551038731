import styled, { keyframes } from "styled-components";

interface MonthYearPickerProps {
  isChecked?: boolean;
}

export const Jelly = keyframes`
  from {
      transform: scale(1, 1);
  }
 
  30% {
      transform: scale(1.1, 0.9);
  }
 
  40% {
      transform: scale(0.9, 1.1);
  }
 
  50% {
      transform: scale(1.05, 0.95);
  }
 
  65% {
      transform: scale(0.98, 1.02);
  }
 
  75% {
      transform: scale(1.02, 0.98);
  }
 
  to {
      transform: scale(1, 1);
  }
`;

export const Span = styled.span`
  position: relative;
  top: 1px;
  width: 20px;
  height: 20px;
  border: 1px solid var(--gray3);
  border-radius: 3px;
  transition: background 0.1s ease;
  cursor: pointer;
  display: block;

  &:after {
    content: "";
    position: absolute;
    left: 6px;
    top: 2px;
    width: 4px;
    height: 9px;
    opacity: 0;
    transform: rotate(45deg) scale(0);
    border-right: 2px solid var(--white);
    border-bottom: 2px solid var(--white);
    transition: all 0.3s ease;
    transition-delay: 0.15s;
  }
`;

export const Input = styled.input<MonthYearPickerProps>`
  position: absolute;
  display: ${({ isChecked }) => (isChecked ? "none" : "block")};
  opacity: 0;

  &:checked ~ ${Span} {
    border-color: transparent;
    background: var(--secondary);
    animation: ${Jelly} 0.6s ease;
  }

  &:checked ~ ${Span}:after {
    opacity: 1;
    transform: rotate(45deg) scale(1);
  }
`;

export const Container = styled.div`
  position: relative;
  height: 20px;
`;
